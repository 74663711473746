import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { loginUser } from '../../redux/actions/profile';
import Alert from '../misc/Alert';
import mainbg from '../resources/mainbg.jpg';

const Login = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });

  const { username, password } = formData;

  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useSelector((state) => state.alert);
  const profile = useSelector((state) => state.profile);

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitAction = (e) => {
    e.preventDefault();
    dispatch(loginUser(formData, history));
  };

  if (localStorage.token) {
    return <Redirect to={'/dashboard'} />;
  }

  return (
    <div
      style={{
        background: `url(${mainbg}) no-repeat center center fixed`,
        backgroundSize: 'cover',
        color: 'white'
      }}
    >
      <div className='mt-5'></div>
      <div className='container'>
        <br />
        <h2>Login to view charter details</h2>
        <br />
        <br />

        <Alert alert={alert} />

        <form onSubmit={(e) => submitAction(e)}>
          <div className='form-group'>
            {/* Username */}
            <div className='row'>
              <div class='col-sm-8'>
                <label for='username'>
                  <h4>Username</h4>
                </label>
              </div>
              <div class='col-sm-8'>
                <input
                  type='username'
                  class='form-control'
                  name='username'
                  value={username}
                  onChange={(e) => changeHandler(e)}
                  required
                />
              </div>
            </div>

            <br />

            {/* Password */}
            <div className='row'>
              <div class='col-sm-8'>
                <label for='password'>
                  <h4>Password</h4>
                </label>
              </div>
              <div class='col-sm-8'>
                <input
                  type='password'
                  class='form-control'
                  name='password'
                  value={password}
                  onChange={(e) => changeHandler(e)}
                  required
                />
              </div>
            </div>

            <br />

            <button
              type='submit'
              class='btn btn-primary'
              disabled={profile.loading}
            >
              {profile.loading ? 'Loading. Please Wait...' : 'Login'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
