import axios from 'axios';
import { setAlert } from './alert';

import {
  SET_LOADING,
  REMOVE_LOADING,
  GET_PROFILE,
  REGISTER_SUCCESS,
  LOGOUT,
  LOGIN_SUCCESS
} from '../types';

export const logoutUser = (history) => (dispatch) => {
  if (localStorage.token) {
    delete axios.defaults.headers.common['x-auth-token'];
    localStorage.removeItem('token');
  }

  dispatch({
    type: LOGOUT
  });

  history.push('/');
};

export const getProfile = (history) => async (dispatch) => {
  dispatch({
    type: SET_LOADING
  });

  try {
    if (localStorage.token) {
      axios.defaults.headers.common['x-auth-token'] = localStorage.token;
    } else {
      delete axios.defaults.headers.common['x-auth-token'];
    }

    const user = await axios.get('/nodeapp/api/info');

    dispatch({
      type: GET_PROFILE,
      payload: user.data
    });

    dispatch({
      type: REMOVE_LOADING
    });
  } catch (err) {
    dispatch({
      type: REMOVE_LOADING
    });
    dispatch({
      type: LOGOUT
    });
    console.log(err.response);
    history.push('/');
  }
};

export const loginUser = (formData, history) => async (dispatch) => {
  dispatch({
    type: SET_LOADING
  });

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify(formData);

  try {
    const res = await axios.post('/nodeapp/api/login', body, config);

    localStorage.setItem('token', res.data.token);

    dispatch({
      type: LOGIN_SUCCESS
    });

    history.push('/dashboard');

    dispatch({
      type: REMOVE_LOADING
    });
  } catch (err) {
    dispatch({
      type: REMOVE_LOADING
    });
    const errorMessages = err.response.data.errors;
    dispatch(setAlert(errorMessages, 'danger'));
  }
};

export const registerUser = (formData, history) => async (dispatch) => {
  dispatch({
    type: SET_LOADING
  });

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify(formData);

  try {
    const res = await axios.post('/nodeapp/api/register', body, config);

    localStorage.setItem('token', res.data.token);

    dispatch({
      type: REGISTER_SUCCESS
    });

    history.push('/dashboard');

    dispatch({
      type: REMOVE_LOADING
    });
  } catch (err) {
    dispatch({
      type: REMOVE_LOADING
    });
    const errorMessages = err.response.data.errors;
    dispatch(setAlert(errorMessages, 'danger'));
  }
};
