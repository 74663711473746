import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Fragment } from 'react';
import MainNavbar from './components/navbar/MainNavbar';
import LandingPage from './components/landing/LandingPage';
import NotFound from './components/notfound/NotFound';
import Register from './components/register/Register';
import Login from './components/login/Login';
import About from './components/about/About';
import Contact from './components/contact/Contact';
import Dashboard from './components/dashboard/Dashboard';
import Admin from './components/admin/Admin';
import store from './redux/store';
import { Provider } from 'react-redux';
import EditProfile from './components/admin/EditProfile';

function App() {
  return (
    <Provider store={store}>
      <div>
        <Router>
          <Fragment>
            <MainNavbar></MainNavbar>
            <Switch>
              <Route exact path='/' component={LandingPage} />
              <Route exact path='/register' component={Register} />
              <Route exact path='/login' component={Login} />
              <Route exact path='/about' component={About} />
              <Route exact path='/contact' component={Contact} />
              <Route exact path='/dashboard' component={Dashboard} />
              <Route exact path='/admin' component={Admin} />
              <Route exact path='/admin/:id' component={EditProfile} />
              <Route component={NotFound} />
            </Switch>
          </Fragment>
        </Router>
      </div>
    </Provider>
  );
}

export default App;
