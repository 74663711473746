import React from 'react';
import { Redirect } from 'react-router-dom';
import mainbg from '../resources/mainbg.jpg';

const NotFound = () => {
  return <Redirect to='/' />;
  return (
    <div
      style={{
        background: `url(${mainbg}) no-repeat center center fixed`,
        backgroundSize: 'cover',
        color: 'white'
      }}
    >
      <div className='mt-5'></div>
      <h1>Sorry this page does not exists</h1>
    </div>
  );
};

export default NotFound;
