import { GET_PROFILES } from '../types';

const initialState = {
  profiles: []
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PROFILES:
      return {
        ...state,
        profiles: payload
      };
    default:
      return state;
  }
};

export default reducer;
