import React from 'react';

const Alert = ({ alert }) => {
  return alert.show ? (
    alert.messages.map((message, index) => (
      <div
        className={'alert alert-' + alert.alertType}
        role='alert'
        key={index}
      >
        {message.msg}
      </div>
    ))
  ) : (
    <div></div>
  );
};

export default Alert;
