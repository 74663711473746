import { SET_ALERT, REMOVE_ALERT } from '../types';

export const setAlert = (messages, alertType) => (dispatch) => {
  console.log(messages);
  dispatch({
    type: SET_ALERT,
    payload: {
      messages,
      alertType
    }
  });

  setTimeout(() => dispatch({ type: REMOVE_ALERT }), 7000);
};
