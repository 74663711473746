import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser } from '../../redux/actions/profile';
import mainbg from '../resources/mainbg.jpg';
import Alert from '../misc/Alert';

const Register = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    company: '',
    representative: '',
    phone: ''
  });

  const {
    username,
    email,
    password,
    company,
    representative,
    phone
  } = formData;

  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useSelector((state) => state.alert);
  const profile = useSelector((state) => state.profile);

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitAction = (e) => {
    e.preventDefault();
    dispatch(registerUser(formData, history));
  };

  if (localStorage.token) {
    return <Redirect to={'/dashboard'} />;
  }

  return (
    <div
      style={{
        background: `url(${mainbg}) no-repeat center center fixed`,
        backgroundSize: 'cover',
        color: 'white'
      }}
    >
      <div className='mt-5'></div>
      <div className='container'>
        <br />
        <h2>Register as a charterer</h2>
        <br />
        <br />

        <Alert alert={alert} />

        <form onSubmit={(e) => submitAction(e)}>
          <div className='form-group'>
            {/* Username */}
            <div className='row'>
              <div class='col-sm-8'>
                <label for='username'>
                  <h4>Username</h4>
                </label>
              </div>
              <div class='col-sm-8'>
                <input
                  type='username'
                  class='form-control'
                  name='username'
                  value={username}
                  onChange={(e) => changeHandler(e)}
                  required
                  placeholder='Example: newtrader2000'
                />
                <small class='form-text'>Choose a unique username</small>
              </div>
            </div>

            <br />

            {/* Email */}
            <div className='row'>
              <div class='col-sm-8'>
                <label for='email'>
                  <h4>Email address</h4>
                </label>
              </div>
              <div class='col-sm-8'>
                <input
                  type='email'
                  class='form-control'
                  name='email'
                  value={email}
                  onChange={(e) => changeHandler(e)}
                  required
                  placeholder='Example: newtrader@gmail.com'
                />
              </div>
            </div>

            <br />

            {/* Password */}
            <div className='row'>
              <div class='col-sm-8'>
                <label for='password'>
                  <h4>Password</h4>
                </label>
              </div>
              <div class='col-sm-8'>
                <input
                  type='password'
                  class='form-control'
                  name='password'
                  value={password}
                  onChange={(e) => changeHandler(e)}
                  required
                />
              </div>
            </div>

            <br />

            {/* Company */}
            <div className='row'>
              <div class='col-sm-8'>
                <label for='password'>
                  <h4>Company Name</h4>
                </label>
              </div>
              <div class='col-sm-8'>
                <input
                  type='text'
                  class='form-control'
                  name='company'
                  value={company}
                  onChange={(e) => changeHandler(e)}
                  required
                  placeholder='Example: Belmont Cruise Company'
                />
              </div>
            </div>

            <br />

            {/* Representative */}
            <div className='row'>
              <div class='col-sm-8'>
                <label for='representative'>
                  <h4>Company Representative / Position</h4>
                </label>
              </div>
              <div class='col-sm-8'>
                <input
                  type='text'
                  class='form-control'
                  name='representative'
                  value={representative}
                  onChange={(e) => changeHandler(e)}
                  required
                  placeholder='Example: Mr. Belmont, CEO'
                />
              </div>
            </div>

            <br />

            {/* Phone */}
            <div className='row'>
              <div class='col-sm-8'>
                <label for='phone'>
                  <h4>Phone Number</h4>
                </label>
              </div>
              <div class='col-sm-8'>
                <input
                  type='text'
                  class='form-control'
                  name='phone'
                  value={phone}
                  onChange={(e) => changeHandler(e)}
                  required
                  placeholder='+1 234 567 8901'
                />
              </div>
            </div>

            <br />

            <button
              type='submit'
              class='btn btn-primary'
              disabled={profile.loading}
            >
              {profile.loading ? 'Loading. Please Wait...' : 'Register'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
