import React from 'react';
import mainbg from '../resources/mainbg.jpg';

const Contact = () => {
  const cardStyle = {
    backgroundColor: 'rgba(74, 0, 196, 0.6)'
  };

  return (
    <div
      style={{
        background: `url(${mainbg}) no-repeat center center fixed`,
        backgroundSize: 'cover',
        color: 'white'
      }}
    >
      <div className='mt-5'></div>
      <div className='container'>
        <div className='pt-5'></div>
        <h1>Contact Us:</h1>
        <div className='pt-3'></div>
        <div class='border border-white rounded mt-5' style={cardStyle}>
          <div className='ml-4 pt-3'>
            <h3>Estonia</h3>
            <p>
              Toompuiestee 173, 10412 Tallinn<br />
              Phone: +372 531 65039<br />
              Email: info@eurotideseastar.com
            </p>
          </div>
        </div>
        <div class='border border-white rounded mt-5' style={cardStyle}>
          <div className='ml-4 pt-3'>
            <h3>Cyprus</h3>
            <p>
              25, Alexandrias Street, CY-3720 Limassol <br />
              Phone: +357 995 5564 1, Fax: +357 99 555416 <br />
              Email: info@eurotideseastar.com
            </p>
          </div>
        </div>
        <div class='border border-white rounded mt-5' style={cardStyle}>
          <div className='ml-4 pt-3'>
            <h3>Latvia</h3>
            <p>
              Matrozu iela 15, Kurzeme Rajons, Riga, LV-1048 Lettonie <br />
              Phone: +371 26 295 146 <br />
              Email: info@eurotideseastar.com
            </p>
          </div>
        </div>
        <div class='border border-white rounded mt-5 mb-4' style={cardStyle}>
          <div className='ml-4 pt-3'>
            <h3>West Coast Africa(Nigeria)</h3>
            <p>
              16, Unity Street/Peter Odili Road, Port Harcourt, Rivers <br />
              Phone: +234 808 949 7927 <br />
              Email: info@eurotideseastar.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
