import React, { useEffect } from 'react';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { getProfile } from '../../redux/actions/profile';
import mainbg from '../resources/mainbg.jpg';

const Dashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.profile);
  const profile = profileData.profile;

  const cardStyle = {
    backgroundColor: 'rgba(74, 0, 196, 0.6)'
  };

  useEffect(() => {
    if (localStorage.token) {
      dispatch(getProfile(history));
    }
  }, []);

  if (!localStorage.token) {
    return <Redirect to={'/'} />;
  }

  if (profile.isadmin === 1) {
    //Redirect to Admin Panel and list all registered companies
    return <Redirect to={'/admin'} />;
    //To request a vessel for charter or manage your current charter voyage, email operators@eurotideseastar.com
  }

  return (
    <div
      style={{
        background: `url(${mainbg}) no-repeat center center fixed`,
        backgroundSize: 'cover',
        color: 'white'
      }}
    >
      <div className='mt-5'></div>
      <div className='container'>
        <br />
        <br />
        <div className='row'>
          <h2 className='col-sm-6'>Dashboard</h2>
        </div>

        <br />
        {profileData.loading ? (
          <h1>Loading...</h1>
        ) : (
          <Fragment>
            <div className='row'>
              <div className='col-sm-6'>
                <div
                  className='card border border-black rounded p-3'
                  style={cardStyle}
                >
                  <h5>Welcome {profile.representative}</h5>
                </div>
              </div>
            </div>

            <br />

            <div className='row mb-3'>
              <div className='col-sm-6 mb-4'>
                <div
                  className='card border border-black rounded p-3'
                  style={cardStyle}
                >
                  <h5>//-Charter Details-//</h5>
                  <br />
                  <h6>
                    Charter ID:{' '}
                    {profile.charterid === 'na'
                      ? 'Not Yet Assigned'
                      : profile.charterid}
                  </h6>
                  <h6>
                    Charter Status:{' '}
                    {profile.charterstatus === 'na'
                      ? 'Not Available'
                      : profile.charterstatus}
                  </h6>
                  <h6>
                    Charter Period:{' '}
                    {profile.charterperiod === 'na'
                      ? 'Not Available'
                      : profile.charterperiod}
                  </h6>
                  <h6>
                    Vessel Name:{' '}
                    {profile.vesselname === 'na'
                      ? 'Not Available'
                      : profile.vesselname}
                  </h6>
                  <h6>
                    Vessel IMO:{' '}
                    {profile.vesselimo === 'na'
                      ? 'Not Available'
                      : profile.vesselimo}
                  </h6>
                  <h6>
                    Vessel MMSI:{' '}
                    {profile.vesselmmsi === 'na'
                      ? 'Not Available'
                      : profile.vesselmmsi}
                  </h6>
                </div>
              </div>

              <div className='col-sm-6 mb-4'>
                <div
                  className='card border border-black rounded p-3'
                  style={cardStyle}
                >
                  <h5>//-Company Info-//</h5>
                  <br />
                  <h6>
                    Company:{' '}
                    {profile.company === 'na'
                      ? 'Not Yet Assigned'
                      : profile.company}
                  </h6>
                  <h6>
                    Representative:{' '}
                    {profile.representative === 'na'
                      ? 'Not Available'
                      : profile.representative}
                  </h6>
                  <h6>
                    Contact:{' '}
                    {profile.phone === 'na' ? 'Not Available' : profile.phone}
                  </h6>
                </div>
              </div>

              <div className='col-sm-6 mb-4'>
                <div
                  className='card border border-black rounded p-3'
                  style={cardStyle}
                >
                  <h5>//-Cargo Details-//</h5>
                  <br />
                  <h6>
                    Cargo:{' '}
                    {profile.cargo === 'na' ? 'Not Available' : profile.cargo}
                  </h6>
                </div>
              </div>

              <div className='col-sm-6 mb-4'>
                <div
                  className='card border border-black rounded p-3'
                  style={cardStyle}
                >
                  <h5>//-Voyage Details-//</h5>
                  <br />
                  <h6>
                    Current Port:{' '}
                    {profile.currentport === 'na'
                      ? 'Not Available'
                      : profile.currentport}
                  </h6>
                  <h6>
                    Destination{' '}
                    {profile.destination === 'na'
                      ? 'Not Available'
                      : profile.destination}
                  </h6>
                </div>
              </div>

              <div className='col-sm-6'>
                <div
                  className='card border border-black rounded p-3'
                  style={cardStyle}
                >
                  <h5>//-Tracking Details-//</h5>
                  <br />
                  {profile.trackeroneurl === 'na' ? (
                    <Fragment>
                      <h6>Marine Traffic Data Not Found</h6>
                    </Fragment>
                  ) : (
                    <a href={profile.trackeroneurl} className='btn btn-info'>
                      {' '}
                      Track On Marine Traffic
                    </a>
                  )}
                  <br />

                  {profile.trackertwourl === 'na' ? (
                    <Fragment>
                      <h6>Fleetmon Data Not Found</h6>
                    </Fragment>
                  ) : (
                    <a href={profile.trackertwourl} className='btn btn-info'>
                      {' '}
                      Track On Fleetmon
                    </a>
                  )}
                  <br />

                  {profile.trackerthreeurl === 'na' ? (
                    <Fragment>
                      <h6>VesselFinder Data Not Found</h6>
                    </Fragment>
                  ) : (
                    <a href={profile.trackerthreeurl} className='btn btn-info'>
                      {' '}
                      Track On VesselFinder
                    </a>
                  )}
                  <br />

                  {profile.trackerfoururl === 'na' ? (
                    <Fragment>
                      <h6>MyShipTracking Data Not Found</h6>
                    </Fragment>
                  ) : (
                    <a href={profile.trackerfoururl} className='btn btn-info'>
                      {' '}
                      Track On MyShipTracking
                    </a>
                  )}
                  <br />

                  {profile.trackerfiveurl === 'na' ? (
                    <Fragment>
                      <h6>VesselTracker Data Not Found</h6>
                    </Fragment>
                  ) : (
                    <a href={profile.trackerfiveurl} className='btn btn-info'>
                      {' '}
                      Track On VesselTracker
                    </a>
                  )}
                  <br />
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
