import React, { useEffect } from 'react';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { getProfiles } from '../../redux/actions/admin';
import mainbg from '../resources/mainbg.jpg';

const Admin = () => {
  const profileData = useSelector((state) => state.profile);
  const profile = profileData.profile;
  const history = useHistory();
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.admin);

  useEffect(() => {
    if (profile.isadmin === 1) {
      dispatch(getProfiles(history));
    }
  }, []);

  if (!localStorage.token) {
    return <Redirect to={'/'} />;
  }

  if (profile.isadmin !== 1) {
    //Redirect to Admin Panel and list all registered companies
    return <Redirect to={'/dashboard'} />;
    //To request a vessel for charter or manage your current charter voyage, email operators@eurotideseastar.com
  }

  return (
    <div
      style={{
        background: `url(${mainbg}) no-repeat center center fixed`,
        backgroundSize: 'cover',
        color: 'white'
      }}
    >
      <div className='mt-5'></div>
      <div className='container'>
        <br />
        <h1>Admin Panel</h1>
        <br />
        <h3>List of Registered Companies</h3>
        <br />
        <ul>
          {admin.profiles ? (
            admin.profiles.map((profile) => (
              <li key={profile.id}>
                <h3>
                  {profile.company}{' '}
                  <Link to={`/admin/${profile.id}`}>
                    <button className='btn btn-primary'>
                      Update Company Status
                    </button>
                  </Link>
                </h3>
              </li>
            ))
          ) : (
            <Fragment></Fragment>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Admin;
