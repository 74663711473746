import axios from 'axios';
import { setAlert } from './alert';

import { SET_LOADING, REMOVE_LOADING, GET_PROFILES, LOGOUT } from '../types';

export const deleteProfile = (id, history) => async (dispatch) => {
  dispatch({
    type: SET_LOADING
  });

  try {
    const res = await axios.delete(`/nodeapp/api/delete/${id}`);

    history.push('/admin');

    dispatch({
      type: REMOVE_LOADING
    });
  } catch (err) {
    dispatch({
      type: REMOVE_LOADING
    });
    const errorMessages = err.response.data.errors;
    dispatch(setAlert(errorMessages, 'danger'));
  }
};

export const updateProfile = (formData, history) => async (dispatch) => {
  dispatch({
    type: SET_LOADING
  });

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify(formData);

  try {
    const res = await axios.put(
      `/nodeapp/api/update/${formData.id}`,
      body,
      config
    );

    history.push('/admin');

    dispatch({
      type: REMOVE_LOADING
    });
  } catch (err) {
    dispatch({
      type: REMOVE_LOADING
    });
    const errorMessages = err.response.data.errors;
    dispatch(setAlert(errorMessages, 'danger'));
  }
};

export const getProfiles = (history) => async (dispatch) => {
  dispatch({
    type: SET_LOADING
  });

  try {
    if (localStorage.token) {
      axios.defaults.headers.common['x-auth-token'] = localStorage.token;
    } else {
      delete axios.defaults.headers.common['x-auth-token'];
    }

    const users = await axios.get('/nodeapp/api/users');

    dispatch({
      type: GET_PROFILES,
      payload: users.data
    });

    dispatch({
      type: REMOVE_LOADING
    });
  } catch (err) {
    dispatch({
      type: REMOVE_LOADING
    });
    dispatch({
      type: LOGOUT
    });
    console.log(err.response);
    history.push('/');
  }
};
