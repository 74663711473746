import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { deleteProfile, updateProfile } from '../../redux/actions/admin';
import mainbg from '../resources/mainbg.jpg';

const EditProfile = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const admin = useSelector((state) => state.admin);
  const profile = useSelector((state) => state.profile);
  const profileContext = admin.profiles.filter(
    (profile) => profile.id === match.params.id
  );

  const [formData, setFormData] = useState({
    id: profileContext[0].id,
    username: profileContext[0].username,
    email: profileContext[0].email,
    password: profileContext[0].password,
    company: profileContext[0].company,
    representative: profileContext[0].representative,
    isadmin: profileContext[0].isadmin,
    charterid: profileContext[0].charterid,
    charterstatus: profileContext[0].charterstatus,
    charterperiod: profileContext[0].charterperiod,
    timeremaining: profileContext[0].timeremaining,
    vesselname: profileContext[0].vesselname,
    vesselimo: profileContext[0].vesselimo,
    vesselmmsi: profileContext[0].vesselmmsi,
    cargo: profileContext[0].cargo,
    currentport: profileContext[0].currentport,
    destination: profileContext[0].destination,
    trackeroneurl: profileContext[0].trackeroneurl,
    trackertwourl: profileContext[0].trackertwourl,
    trackerthreeurl: profileContext[0].trackerthreeurl,
    trackerfoururl: profileContext[0].trackerfoururl,
    trackerfiveurl: profileContext[0].trackerfiveurl,
    createdat: profileContext[0].createdat
  });

  const {
    id,
    username,
    email,
    company,
    representative,
    isadmin,
    charterid,
    charterstatus,
    charterperiod,
    timeremaining,
    vesselname,
    vesselimo,
    vesselmmsi,
    cargo,
    currentport,
    destination,
    trackeroneurl,
    trackertwourl,
    trackerthreeurl,
    trackerfoururl,
    trackerfiveurl
  } = formData;

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitAction = (e) => {
    e.preventDefault();
    dispatch(updateProfile(formData, history));
  };

  const deleteAction = (e) => {
    e.preventDefault();
    dispatch(deleteProfile(id, history));
  };

  return (
    <div
      style={{
        background: `url(${mainbg}) no-repeat center center fixed`,
        backgroundSize: 'cover',
        color: 'white'
      }}
    >
      <div className='mt-5'></div>
      <div className='container'>
        <br />
        <h1>Edit Company</h1>
        <br />
        <div>
          <Link className='mr-5' to={'/admin'}>
            <button className='btn btn-primary'>Back To Admin</button>
          </Link>

          <button
            onClick={(e) => deleteAction(e)}
            className='btn btn-danger ml-5'
          >
            Delete Company
          </button>
        </div>
        <br />
        <br />
        {/* Form */}
        {/* Form */}
        {/* Form */}
        <form onSubmit={(e) => submitAction(e)}>
          <div className='form-group'>
            {/* Email */}
            <div className='row'>
              <div class='col-sm-8'>
                <label for='email'>
                  <h4>Email</h4>
                </label>
              </div>
              <div class='col-sm-8'>
                <input
                  type='email'
                  class='form-control'
                  name='email'
                  value={email}
                  onChange={(e) => changeHandler(e)}
                  required
                />
              </div>
            </div>

            <br />

            {/* Username */}
            <div className='row'>
              <div class='col-sm-8'>
                <label for='username'>
                  <h4>Username</h4>
                </label>
              </div>
              <div class='col-sm-8'>
                <input
                  type='text'
                  class='form-control'
                  name='username'
                  value={username}
                  onChange={(e) => changeHandler(e)}
                  required
                />
              </div>
            </div>

            <br />

            {/* Representative */}
            <div className='row'>
              <div class='col-sm-8'>
                <label for='representative'>
                  <h4>Representative</h4>
                </label>
              </div>
              <div class='col-sm-8'>
                <input
                  type='text'
                  class='form-control'
                  name='representative'
                  value={representative}
                  onChange={(e) => changeHandler(e)}
                  required
                />
              </div>
            </div>

            <br />

            {/* Company */}
            <div className='row'>
              <div class='col-sm-8'>
                <label for='isadmin'>
                  <h4>Company</h4>
                </label>
              </div>
              <div class='col-sm-8'>
                <input
                  type='text'
                  class='form-control'
                  name='company'
                  value={company}
                  onChange={(e) => changeHandler(e)}
                  required
                />
              </div>
            </div>

            <br />

            {/* IsAdmin */}
            <div className='row'>
              <div class='col-sm-8'>
                <label for='isadmin'>
                  <h4>Is Admin?</h4>
                </label>
              </div>
              <div class='col-sm-8'>
                <input
                  type='text'
                  class='form-control'
                  name='isadmin'
                  value={isadmin}
                  onChange={(e) => changeHandler(e)}
                  required
                />
              </div>
            </div>

            <br />

            {/* Charter Id */}
            <div className='row'>
              <div class='col-sm-8'>
                <label for='charterid'>
                  <h4>Charter Id</h4>
                </label>
              </div>
              <div class='col-sm-8'>
                <input
                  type='text'
                  class='form-control'
                  name='charterid'
                  value={charterid}
                  onChange={(e) => changeHandler(e)}
                  required
                />
              </div>
            </div>

            <br />

            {/* Charter Status */}
            <div className='row'>
              <div class='col-sm-8'>
                <label for='charterstatus'>
                  <h4>Charter Status</h4>
                </label>
              </div>
              <div class='col-sm-8'>
                <input
                  type='text'
                  class='form-control'
                  name='charterstatus'
                  value={charterstatus}
                  onChange={(e) => changeHandler(e)}
                  required
                />
              </div>
            </div>

            <br />

            {/* Charter Period */}
            <div className='row'>
              <div class='col-sm-8'>
                <label for='charterperiod'>
                  <h4>Charter Period</h4>
                </label>
              </div>
              <div class='col-sm-8'>
                <input
                  type='text'
                  class='form-control'
                  name='charterperiod'
                  value={charterperiod}
                  onChange={(e) => changeHandler(e)}
                  required
                />
              </div>
            </div>

            <br />

            {/* Time Remaining */}
            <div className='row'>
              <div class='col-sm-8'>
                <label for='timeremaining'>
                  <h4>Time Remaining</h4>
                </label>
              </div>
              <div class='col-sm-8'>
                <input
                  type='text'
                  class='form-control'
                  name='timeremaining'
                  value={timeremaining}
                  onChange={(e) => changeHandler(e)}
                  required
                />
              </div>
            </div>

            <br />

            {/* Vessel Name */}
            <div className='row'>
              <div class='col-sm-8'>
                <label for='vesselname'>
                  <h4>Vessel Name</h4>
                </label>
              </div>
              <div class='col-sm-8'>
                <input
                  type='text'
                  class='form-control'
                  name='vesselname'
                  value={vesselname}
                  onChange={(e) => changeHandler(e)}
                  required
                />
              </div>
            </div>

            <br />

            {/* Vessel IMO */}
            <div className='row'>
              <div class='col-sm-8'>
                <label for='vesselimo'>
                  <h4>Vessel IMO</h4>
                </label>
              </div>
              <div class='col-sm-8'>
                <input
                  type='text'
                  class='form-control'
                  name='vesselimo'
                  value={vesselimo}
                  onChange={(e) => changeHandler(e)}
                  required
                />
              </div>
            </div>

            <br />

            {/* Vessel MMSI */}
            <div className='row'>
              <div class='col-sm-8'>
                <label for='vesselmmsi'>
                  <h4>Vessel MMSI</h4>
                </label>
              </div>
              <div class='col-sm-8'>
                <input
                  type='text'
                  class='form-control'
                  name='vesselmmsi'
                  value={vesselmmsi}
                  onChange={(e) => changeHandler(e)}
                  required
                />
              </div>
            </div>

            <br />

            {/* Cargo */}
            <div className='row'>
              <div class='col-sm-8'>
                <label for='cargo'>
                  <h4>Cargo</h4>
                </label>
              </div>
              <div class='col-sm-8'>
                <input
                  type='text'
                  class='form-control'
                  name='cargo'
                  value={cargo}
                  onChange={(e) => changeHandler(e)}
                  required
                />
              </div>
            </div>

            <br />

            {/* Current Port */}
            <div className='row'>
              <div class='col-sm-8'>
                <label for='currentport'>
                  <h4>Current Port</h4>
                </label>
              </div>
              <div class='col-sm-8'>
                <input
                  type='text'
                  class='form-control'
                  name='currentport'
                  value={currentport}
                  onChange={(e) => changeHandler(e)}
                  required
                />
              </div>
            </div>

            <br />

            {/* Destination */}
            <div className='row'>
              <div class='col-sm-8'>
                <label for='destination'>
                  <h4>Destination</h4>
                </label>
              </div>
              <div class='col-sm-8'>
                <input
                  type='text'
                  class='form-control'
                  name='destination'
                  value={destination}
                  onChange={(e) => changeHandler(e)}
                  required
                />
              </div>
            </div>

            <br />

            {/* Tracker One URL */}
            <div className='row'>
              <div class='col-sm-8'>
                <label for='trackeroneurl'>
                  <h4>Tracker One URL</h4>
                </label>
              </div>
              <div class='col-sm-8'>
                <input
                  type='text'
                  class='form-control'
                  name='trackeroneurl'
                  value={trackeroneurl}
                  onChange={(e) => changeHandler(e)}
                  required
                />
              </div>
            </div>

            <br />

            {/* Tracker Two URL */}
            <div className='row'>
              <div class='col-sm-8'>
                <label for='trackertwourl'>
                  <h4>Tracker Two URL</h4>
                </label>
              </div>
              <div class='col-sm-8'>
                <input
                  type='text'
                  class='form-control'
                  name='trackertwourl'
                  value={trackertwourl}
                  onChange={(e) => changeHandler(e)}
                  required
                />
              </div>
            </div>

            <br />

            {/* Tracker Three URL */}
            <div className='row'>
              <div class='col-sm-8'>
                <label for='trackerthreeurl'>
                  <h4>Tracker Three URL</h4>
                </label>
              </div>
              <div class='col-sm-8'>
                <input
                  type='text'
                  class='form-control'
                  name='trackerthreeurl'
                  value={trackerthreeurl}
                  onChange={(e) => changeHandler(e)}
                  required
                />
              </div>
            </div>

            <br />

            {/* Tracker Four URL */}
            <div className='row'>
              <div class='col-sm-8'>
                <label for='trackerfoururl'>
                  <h4>Tracker Four URL</h4>
                </label>
              </div>
              <div class='col-sm-8'>
                <input
                  type='text'
                  class='form-control'
                  name='trackerfoururl'
                  value={trackerfoururl}
                  onChange={(e) => changeHandler(e)}
                  required
                />
              </div>
            </div>

            <br />

            {/* Tracker Four URL */}
            <div className='row'>
              <div class='col-sm-8'>
                <label for='trackerfiveurl'>
                  <h4>Tracker Five URL</h4>
                </label>
              </div>
              <div class='col-sm-8'>
                <input
                  type='text'
                  class='form-control'
                  name='trackerfiveurl'
                  value={trackerfiveurl}
                  onChange={(e) => changeHandler(e)}
                  required
                />
              </div>
            </div>

            <br />

            <button
              type='submit'
              class='btn btn-primary'
              disabled={profile.loading}
            >
              {profile.loading ? 'Loading. Please Wait...' : 'Update'}
            </button>
          </div>
        </form>
        {/* Form */}
        {/* Form */}
        {/* Form */}
      </div>
    </div>
  );
};

export default EditProfile;
