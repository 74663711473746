import React, { createRef, Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { logoutUser } from '../../redux/actions/profile';
import icon from './icon.png';

const MainNavbar = () => {
  const linkColor = {
    color: 'black'
  };

  const navRef = createRef();

  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector((state) => state.profile);

  const collapseNavbar = (e) => {
    navRef.current.click();
  };

  const navLogout = (e) => {
    collapseNavbar(e);
    dispatch(logoutUser(history));
  };
  return (
    <div>
      <nav
        className='navbar fixed-top navbar-expand-lg navbar-light'
        style={{ backgroundColor: 'white' }}
      >
        <div className='container'>
          <img
            className='mr-2'
            src={icon}
            style={{ width: '50px', margin: 'auto', display: 'block' }}
            alt=''
          />
          <Link to='/' className='navbar-brand mr-auto' style={linkColor}>
            EUROTIDE SEASTAR
          </Link>
          <button
            className='navbar-toggler ml-auto'
            type='button'
            data-toggle='collapse'
            data-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
            ref={navRef}
          >
            <span className='navbar-toggler-icon'></span>
          </button>

          <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            <ul className='navbar-nav ml-auto'>
              {localStorage.token ? (
                <Fragment>
                  <li className='nav-item'>
                    <Link
                      onClick={(e) => navLogout(e)}
                      className='nav-link'
                      style={linkColor}
                    >
                      Logout
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      onClick={(e) => collapseNavbar(e)}
                      to='/dashboard'
                      className='nav-link'
                      style={linkColor}
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      onClick={(e) => collapseNavbar(e)}
                      to='/about'
                      className='nav-link'
                      style={linkColor}
                    >
                      About
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      onClick={(e) => collapseNavbar(e)}
                      to='/contact'
                      className='nav-link'
                      style={linkColor}
                    >
                      Contact Us
                    </Link>
                  </li>
                </Fragment>
              ) : (
                <Fragment>
                  <li className='nav-item'>
                    <Link
                      onClick={(e) => collapseNavbar(e)}
                      to='/register'
                      className='nav-link'
                      style={linkColor}
                    >
                      Register
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      onClick={(e) => collapseNavbar(e)}
                      to='/login'
                      className='nav-link'
                      style={linkColor}
                    >
                      Login
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      onClick={(e) => collapseNavbar(e)}
                      to='/about'
                      className='nav-link'
                      style={linkColor}
                    >
                      About
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      onClick={(e) => collapseNavbar(e)}
                      to='/contact'
                      className='nav-link'
                      style={linkColor}
                    >
                      Contact Us
                    </Link>
                  </li>
                </Fragment>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default MainNavbar;
