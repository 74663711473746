import React from 'react';
import { Button, MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import white from '@material-ui/core/colors/';
import shiplanes from './shiplanes.png';
import partner1 from './partner1.png';
import partner2 from './partner2.png';
import partner3 from './partner3.svg';
import partner4 from './partner4.png';
import mainbg from '../resources/mainbg.jpg';
import { Redirect, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const LandingPage = () => {
  const history = useHistory();
  const profile = useSelector((state) => state.profile);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: 'rgb(255,255,255)'
      }
    }
  });

  if (localStorage.token) {
    return <Redirect to={'/dashboard'} />;
  }

  return (
    <div
      style={{
        background: `url(${mainbg}) no-repeat center center fixed`,
        backgroundSize: 'cover',
        color: 'white'
      }}
    >
      <div className='mt-5'></div>
      <div
        className='pt-5'
        style={{
          textAlign: 'center'
        }}
      >
        <img
          className='pt-5'
          src={shiplanes}
          style={{ width: '350px', margin: 'auto', display: 'block' }}
          alt=''
        />
        <h1 className='mt-5'>Effortless Tanker Management</h1>
        <h1 className='pt-1'>World class guardianship</h1>
        <h5 className='pt-3' style={{ color: 'white' }}>
          With over 6000 successful charters, we give you the right solutions{' '}
          <br />
          to ship your cargo from point A to point B.
        </h5>
        <MuiThemeProvider theme={theme}>
          <Button
            onClick={(e) => history.push('/register')}
            variant='outlined'
            color='primary'
            className='mx-3 mt-3'
          >
            Register
          </Button>
          <Button
            onClick={(e) => history.push('/login')}
            variant='outlined'
            color='primary'
            className='mt-3 mx-3'
          >
            Login
          </Button>
        </MuiThemeProvider>

        <h2 className='mt-5 mb-4'>Partners</h2>
        <img
          className='mx-4 mt-3'
          src={partner1}
          height={70}
          width={120}
          alt=''
        />
        <img
          className='mx-4 mt-3'
          src={partner2}
          height={70}
          width={120}
          alt=''
        />
        <img
          className='mx-4 mt-3'
          src={partner3}
          height={70}
          width={140}
          alt=''
        />
        <img className='mt-3' src={partner4} height={70} width={140} alt='' />
        <div className='pt-5'></div>
      </div>
    </div>
  );
};

export default LandingPage;
