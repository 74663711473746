import React from 'react';
import mainbg from '../resources/mainbg.jpg';

const About = () => {
  return (
    <div
      style={{
        background: `url(${mainbg}) no-repeat center center fixed`,
        backgroundSize: 'cover',
        color: 'white'
      }}
    >
      <div className='container'>
        <div className='my-5'></div>
        <br />
        <h1 className='mb-3'>About us</h1>
        <br />
        <h4>Who we are</h4>
        <p>
          <strong>
            EUROTIDE SEASTAR represents about 0.4% of petroleum products
            shipping per year and growing. In our first 10 years, EUROTIDE
            SEASTAR has developed more than 30 standards that are followed
            critically for each and every shipment handled.
          </strong>
        </p>
        <p>
          <strong>
            In recent years our work has expanded to include a growing
            international dimension, and today EUROTIDE SEASTAR is recognized
            around the world for its broad range and flexibility in the
            petroleum products shipping business while still upholding our
            standards with a current lineup of 6 vessels varying up to
            ULCC(Ultra Large Crude Carrier).
          </strong>
        </p>

        <br />
        <br />
        <h4>Principles</h4>
        <p>
          <strong>
            EUROTIDE SEASTAR is a problem solver that tackles great petroleum
            shipping challenges while meeting the growing demand for vessel
            chartering services with over 6000 charters served till date.
          </strong>
        </p>
        <p>
          <strong>
            To fulfill this responsibility, we uphold the following principles:
            <ol>
              <li>
                Advance safe and responsible petroleum products shipping and
                join the leadership in meeting the growing demand of affordable
                and reliable vessel chartering services.
              </li>
              <li>
                Using modern maritime practices and technology, continuously
                keep activities that have serious environmental impact on a low
                side.
              </li>
              <li>
                Commit to enhance the integrity of operations across our
                organization.
              </li>
            </ol>
          </strong>
        </p>
      </div>
    </div>
  );
};

export default About;
