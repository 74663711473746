import {
  SET_LOADING,
  REMOVE_LOADING,
  GET_PROFILE,
  REGISTER_SUCCESS,
  LOGOUT,
  LOGIN_SUCCESS
} from '../types';

const initialState = {
  profile: {},
  loading: false,
  isAuthenticated: false
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case REMOVE_LOADING:
      return {
        ...state,
        loading: false
      };
    case GET_PROFILE:
      return {
        ...state,
        profile: payload
      };
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        profile: {}
      };
    default:
      return state;
  }
};

export default reducer;
